<template>
  <div>
    <el-dialog title="导出"
               :visible.sync="dialogVisible"
               :close-on-click-modal="true"
               show-close
               :before-close="handleClose">
      <!-- <div>
        <span style="margin-left: -21px;">星级：</span>
        <el-select v-model="starValue"
                   size="mini"
                   clearable
                   :popper-append-to-body="false"
                   placeholder="请选择">
          <el-option v-for="item in cities"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
      <!-- </el-option>
        </el-select>
      </div> -->
      <el-form :model="form"
               ref="formRef"
               label-width="100px">
        <div class="midtitle">考情报告：</div>
        <el-form-item prop="export"
                      style="margin-left: 24px;">
          <span style="margin-left: -21px;">导出：</span>
          <el-radio-group v-model="radio">
            <el-radio :label="1">导出</el-radio>
            <el-radio :label="0">不导出</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="midtitle">定制教辅：</div>
        <el-form-item prop="teaching_assistant">
          <span style="margin-left: -21px;">导出科目：</span>
          <el-checkbox v-model="checkAll"
                       @change="handleCheckAllChange"
                       style="margin-bottom: 3px;">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCitiesOpt">
            <el-checkbox v-for="city in cityOptions"
                         :label="city.subject_name"
                         :key="city.id">{{city.subject_name}}</el-checkbox>
          </el-checkbox-group>

        </el-form-item>
        <!-- <div class="midtitle">定制题集：</div>
        <el-form-item prop="question_list">
          <span style="margin-left: -21px;">导出科目：</span>
          <el-checkbox v-model="checkAll2"
                       @change="handleCheckAllChange2"
                       style="margin-bottom: 3px;">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCitiesOpt2">
            <el-checkbox v-for="city in cityOptions2"
                         :label="city.subject_name"
                         :key="city.id">{{city.subject_name}}</el-checkbox>
          </el-checkbox-group>

        </el-form-item> -->
        <el-form-item prop="star" style="margin-left: 26px;">
          <span style="margin-left: -21px;">星级：</span>
          <el-select v-model="starValue"
                     size="mini"
                     clearable
                     multiple
                     :popper-append-to-body="false"
                     placeholder="请选择">
            <el-option v-for="item in cities"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="btn_warp">
        <div @click="exportBtn">确定</div>
        <div @click="handleClose">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { getExplainReport, getTeacherReport, getDetectionList } from '@/api/dataAnalysis.js'
// import { getDetectionList } from '@/api/getSubjectList.js'
import { saveAs } from "file-saver"
import { loadings } from '@/utils/http.js'
export default {
  data () {
    return {
      dialogVisible: false,
      cityOptions: [],
      cityOptions2: [],
      radio: 1,
      form: {
      },
      // has_explain: 1,
      checkAll: false,
      checkAll2: false,
      checkedCities: [],
      checkedCitiesOpt: [],
      checkedCitiesOpt2: [],
      select_all: 0,
      select_all2: 0,
      detection_id: '',
      cities: [{
        value: '1',
        label: '一星'
      }, {
        value: '2',
        label: '二星'
      }, {
        value: '3',
        label: '三星'
      }, {
        value: '4',
        label: '四星'
      }, {
        value: '5',
        label: '五星'
      }],
      starValue: []
    }
  },
  async created () {

  },
  async mounted () {

  },
  watch: {
    checkedCitiesOpt: function (newVal, oldVal) {

      if (newVal.length < this.cityOptions.length) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }

    },
    checkedCitiesOpt2: function (newVal, oldVal) {

      if (newVal.length < this.cityOptions2.length) {
        this.checkAll2 = false
      } else {
        this.checkAll2 = true
      }

    },

  },
  methods: {
    async openDialog () {
      console.log('520', this.detection_id)
      const { data } = await getDetectionList({ detection_id: this.detection_id })
      console.log('data', data)
      this.cityOptions = data.list.map(item => {
        return {
          subject_id: item.subject_id,
          subject_name: item.subject_name,
        }
      });
      this.cityOptions2 = data.list.map(item => {
        return {
          subject_id: item.subject_id,
          subject_name: item.subject_name,
        }
      });
    },
    handleCheckAllChange (val) {
      this.checkAll = val;
      if (val) {
        this.cityOptions.forEach(item => {
          this.checkedCitiesOpt.push(item.subject_name)
        })
        this.select_all = 1
      } else {
        this.checkedCitiesOpt = []
        this.select_all = 0
      }
    },
    handleCheckAllChange2 (val) {
      this.checkAll2 = val;
      if (val) {
        this.cityOptions2.forEach(item => {
          this.checkedCitiesOpt2.push(item.subject_name)
        })
        this.select_all2 = 1
      } else {
        this.checkedCitiesOpt2 = []
        this.select_all2 = 0
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
      this.starValue = []
      // this.$parent.refresh()
    },
    async exportReport () {
      this.btnDisabled = true
      try {
        // let arr = []
        // arr.push(this.starValue)
        const data = await getTeacherReport({ detection_id: this.detection_id, star: this.starValue })
        if (data.size > 0) {
          this.btnDisabled = false
        }
        let blob = new Blob([data], { type: 'application/msword;charset=utf-8' });
        saveAs(blob, `考情报告.docx`)
      } catch {
        this.$notify({
          title: '提示',
          message: '未有相关做卷信息!',
          type: 'error',
        });
         //关闭loading加载动画
         loadings.close()
      }
    },
    exportBtn () {
      if (this.radio == 1) {
        this.exportReport()
      }
      let exportArr = []
      let exportArr2 = []
      this.cityOptions.forEach(element => {
        this.checkedCitiesOpt.forEach(item => {
          if (element.subject_name == item) {
            exportArr.push(element)
          }
        })
      });
      this.cityOptions2.forEach(element => {
        this.checkedCitiesOpt2.forEach(item => {
          if (element.subject_name == item) {
            exportArr2.push(element)
          }
        })
      });
      exportArr.forEach(item => {
        this.postExport(item.subject_id, item.subject_name, this.checkAll, 1)
      })
      exportArr2.forEach(item => {
        this.postExport(item.subject_id, item.subject_name, this.checkAll2, 0)
      })
      this.handleClose()

    },
    async postExport (subject_id, subject_name, select_all, has_explain) {
      // let arr = []
      // arr.push(this.starValue)
      const data = await getExplainReport({ detection_id: this.detection_id, has_explain: has_explain, subject_id, select_all: select_all.toString(), star: this.starValue })
      if (data.size > 0) {
        this.$parent.btnDisabled = false
      }
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      if (this.checkAll) {
        if (has_explain == 1) {
          saveAs(blob, `${subject_name}教辅.docx`)
        } else {
          saveAs(blob, `${subject_name}题集.docx`)
        }
      } else {
        if (has_explain == 1) {
          saveAs(blob, `${subject_name}教辅.docx`)
        } else {
          saveAs(blob, `${subject_name}题集.docx`)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.midtitle {
  font-size: 14px;

  font-weight: 600;
  color: #333333;
  -webkit-background-clip: text;
}
.btn_warp {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  width: 432px;
  height: 32px;
  margin-bottom: 24px;
  flex-direction: row-reverse;

  div {
    // flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      width: 60px;
      height: 32px;
      cursor: pointer;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #dbdbdb;
      // padding-bottom: 24px;
    }
    &:nth-child(1) {
      width: 60px;
      height: 32px;
      cursor: pointer;
      background: #3f9eff !important;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      margin-left: 20px;
      color: #ffffff;
      // border: 1px solid #dbdbdb;
      -webkit-background-clip: text;
    }
  }
}
::v-deep .el-dialog__body {
  margin-top: 12px;
  padding: 24px;
  height: auto;
}
::v-deep .el-form {
  max-height: 458px;
  margin-bottom: 50px;
}
::v-deep .el-dialog__header {
  text-align: left !important;
  width: 432px;
  height: 30px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 24px;

  font-weight: 500;
  color: #333333;
  line-height: 30px;
  -webkit-background-clip: text;
}
::v-deep .el-dialog {
  border-radius: 10px;
  width: 500px;
  // height: 610px !important;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  height: auto;
  min-height: 350px;
}

::v-deep .el-textarea__inner {
  font-size: 16px;
}
::v-deep .el-form-item__content {
  margin-left: 32px !important;
}
::v-deep .el-checkbox__inner {
  width: 16px;
  height: 16px;
  border-radius: 2px 2px 2px 2px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #3f9eff;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #3f9eff;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #3f9eff;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #3f9eff;
}
// ::v-deep .el-input__inner {
//   height: 45px;
//   line-height: 45px;
//   font-size: 16px;
// }
::v-deep .el-form-item__label {
  line-height: 45px;
  font-size: 18px;
}
::v-deep .el-dialog__title {
  width: 144px;
  height: 36px;
  font-size: 20px;

  font-weight: 600;
  color: #333333;
  line-height: 36px;
  -webkit-background-clip: text;
}
::v-deep .el-dialog__headerbtn {
  top: 29px;
}
::v-deep .el-checkbox-group {
  text-align: left; //这个很关键，否则最后一行是居中状态
  width: auto;
  margin-left: 49px;
}

::v-deep .el-checkbox {
  margin-left: 0px;
  // margin-right: 25px;
  width: 62px; //根据内容设置宽度
  padding-left: 0px;
  text-align: left; //这个很关键，否则每一行都是居中状态
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #666666;
  border: none;
  padding: 0px;
  background: none;
}
::v-deep .el-select {
  width: 29%;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
::v-deep .el-select-dropdown {
  min-width: 130px !important;
}
</style>