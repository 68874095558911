<template>
  <div class="main"
       v-show='true'>
    <div style="display:flex;">

      <div>
        <div style="display:inline-block;margin-left:12px;color:#606266">手机/姓名/ID：</div>
        <el-input placeholder="请输入内容"
                  v-model="keywordVal"
                  :style="{ width: '60%' }"
                  clearable>
        </el-input>
      </div>
      <el-button type="primary"
                 style="margin-bottom:12px"
                 @click="search">查询</el-button>
      <el-button type="primary"
                 v-if="is_admin == 1"
                 style="margin-bottom:12px"
                 @click="toAdd">新增</el-button>
      <el-button type="primary"
                 v-if="edu_school_id == 1"
                 @click="importExcelFunc">导入光大数据</el-button>
    </div>

    <el-table border
              highlight-current-row
              type=""
              :stripe="true"
              :max-height="$store.state.tableHeight + 53 "
              :data="tableData"
              style="width: 100%;min-height:617px">

      <el-table-column prop="id"
                       align="left"
                       label="ID" />
      <el-table-column prop="verifiedMobile"
                       align="left"
                       label="学号/手机号" />

      <el-table-column prop="truename"
                       align="left"
                       label="学生姓名" />
      <el-table-column prop="name"
                       align="left"
                       fixed="right"
                       label="操作">

        <template slot-scope="{row}">
          <div class="text_btn_primary"
               @click="toReport(row)">检测报告</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <Shuttle ref="ShuttleRef" /> -->
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <centerDialog ref="DialogRef" />
    <importExcel ref="importExcelRef" />
  </div>
</template>

<script>
import importExcel from "@/views/dataAnalysis/components/importExcel.vue"
import centerDialog from "@/views/dataAnalysis/components/centerDialog.vue"

export default {
  name: 'checkTest',
  data () {
    return {
      form: {
        user_id: '',
        subject_id: '',
        page: 1,
        limit: 10,
        keyword: ''
      },
      suubjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      is_admin: '',
      keywordVal: '',
      edu_school_id: '',
    }
  },

  components: {
    centerDialog,importExcel
  },
  mounted () {
    this.is_admin = JSON.parse(window.localStorage.getItem('userInfo')).is_admin
    this.edu_school_id = JSON.parse(window.localStorage.getItem('userInfo')).edu_school_id
    this.getSubject()
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '32px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '114px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
    window.onpopstate = function (event) {
      location.reload(); // 当路由回退时重新加载整个页面
    };

  },
  methods: {
    search () {
      this.initData()
    },
    toAdd () {
      this.$router.push('/dataAnalysis/addStu')
    },
    refreshPage () {
      location.reload();
    },
    showDialog () {
      this.$refs.DialogRef.dialogVisible = true
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.form.keyword = this.keywordVal
      this.$http({
        url: '/api/v1/tang/user_list',
        method: 'get',
        params: this.form
      }).then(res => {
        console.log('ressss', res)
        this.info = res.data
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
        importExcelFunc () {
      let form = {
        district_id: '',
        given_garde: '',
        source_paper: 1,
        question_year: '',
        source_id: '',
        subject_id: '',
      }
      this.$refs.importExcelRef.form = form
      this.$refs.importExcelRef.getList()
      this.$refs.importExcelRef.dialogVisible = true
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.suubjectList = res.data.subject
      })
    },
    // 修改状态为未改
    editStatus (row) {
      this.$confirm("确定将其修改为未批改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/papers/edit_paper_status',
          method: 'post',
          data: {
            user_paper_id: row.user_paper_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    toReport (row) {
      console.log('第', window.localStorage.getItem('user_id'))
      window.localStorage.setItem('user_id', row.id)

      this.$router.push(
        {
          path: '/dataAnalysis/report',
          query: {
            user_id: row.id,
            user_paper_id: row.user_paper_id
          }
        })
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    checkInfo (row) {
      this.$router.push('/checkTestAll/checkTestPage')
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 12px 12px 0 12px;
  background: white;
  // font-family: Regular;
  // font-weight: 400px;
}
::v-deep .text_btn_primary {
  color: #3f9eff;
}
// ::v-deep .el-table__header-wrapper {
//   font-family: Medium;
// }
// ::v-deep th {
//   font-weight: normal;
//   font-family: Medium;
// }
::v-deep .el-pagination {
  font-family: "Regular";
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
.pedding {
  color: green;
}
</style>